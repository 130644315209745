<template>
    <div>
        <div class="user">
            <img class="avatar" :src="user.avatar" alt="" @click="deleteItem(1)">
            <div class="text">{{ user.name }}</div>
        </div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <van-swipe-cell  v-for="item in datas" :key="item.id">
                    <van-cell :title="item.name" :value="item.date" :label="item.tel" />
                    <template #right>
                        <van-button square text="删除" type="danger" class="delete-button" @click="deleteItem(item.id)" />
                    </template>
                </van-swipe-cell>
            </van-list>
        </van-pull-refresh>
    </div>
</template>
<script>
import user from '../../mixins/user.js'
import axios from 'axios'
import {
    Tag,
    Col,
    Cell,
    CellGroup,
    Toast,
    DropdownMenu,
    DropdownItem,
    Button,
    Switch,
    PullRefresh,
    List,
    SwipeCell,
    Dialog
} from 'vant';

export default {
    components: {
        [Tag.name]: Tag,
        [Col.name]: Col,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Button.name]: Button,
        [Switch.name]: Switch,
        [PullRefresh.name]:PullRefresh,
        [List.name]:List,
        [SwipeCell.name]:SwipeCell,
        [Dialog.Component.name]: Dialog.Component
    },
    mixins: [
        user
    ],
    data() {
        return {
            user: {
                name: '',
                avatar: 'https://img.alicdn.com/imgextra/i3/O1CN01Mpftes1gwqxuL0ZQE_!!6000000004207-2-tps-240-240.png'
            },
            state: false,
            datas:[],
            isLoading: false,
            loading: false,
            finished: false
        }
    },
    mounted() {
        this.getData()
        
    },
    methods: {
        
        getData() {
            this.isLoading = true;
            axios.post('https://ydwx.83277777.com/api/bookhs/getData', {exam_project:this.exam_project,state:this.state}).then(res => {
                    this.datas = res.data.data
                    this.isLoading = false;
                    this.loading = false
                }).catch((e) => {
                    console.log(e)
                })
        },

        onConfirm() {
            this.$refs.item.toggle();
            this.getData()
        },
        onRefresh() {
            Toast('刷新成功');
            this.isLoading = false;
        },
        onLoad() {
            this.loading = false
            console.log('loading')
        },
        sorry() {
            Toast('暂无后续逻辑~');
        },
        deleteItem(id) {
            Dialog.confirm({
              message: '确定删除吗？',
            }).then(() => {
              Toast.loading({duration: 0});
              axios.post('https://ydwx.83277777.com/api/bookhs/delete', {id:id}).then(res => {
                      console.log(res)
                      this.loading = false
                      Toast.clear()
                      this.getData()
                  }).catch((e) => {
                      console.log(e)
                  })

            });
        },
        beforeClose({ position, instance }) {
              switch (position) {
                case 'left':
                case 'cell':
                case 'outside':
                  instance.close();
                  break;
                case 'right':
                  Dialog.confirm({
                    message: '确定删除吗？',
                  }).then(() => {
                    instance.close();
                  });
                  break;
            }
        }
    },

};
</script>
<style lang="less">
.user {
    text-align: center;
    padding: 20px 0;
    background-color: #fff;
    margin-bottom: 10px;
    .avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .text {
        margin-top: 5px;
    }
}
.delete-button {
    height: 100%;
}
</style>